export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#222",
  fullblack: "#000",
  grayscale2: "#FAFAFA",
  grayscale3: "#EAEAEA",
  grayscale4: "#B5B5B5",
  grayscale5: "#8B8B8B",
  grayscale6: "#3B3B3B",
  grayscale7: "#1B1B1B",
  primary: "#393E46",
  secondary: "#D0A144",
  ternary: "#FAFAFA",
  success: "#3AD19D",
  danger: "#DC4141",
  info: "#4C9AFF",
  warning: "#F7B500",
}
