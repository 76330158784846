import styled, { css, keyframes } from "styled-components"
import PropTypes from "prop-types"
import { darken, rgba } from "polished"

import { colors } from "../../styles/jscolors"

const buttonThemes = {
  default: css`
    color: var(--color-white);
    background-color: var(--color-grayscale5);
    border: 1px solid var(--color-grayscale5);
    &:hover,
    &:focus {
      background-color: var(--color-grayscale6);
      border-color: var(--color-grayscale6);
    }
    &:active {
      background-color: var(--color-grayscale5);
      border-color: var(--color-grayscale5);
    }
  `,
  primary: css`
    color: var(--color-white);
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    &:hover,
    &:focus {
      background-color: ${darken(0.09, colors.primary)};
      border-color: ${darken(0.09, colors.primary)};
    }
    &:active {
      background-color: ${darken(0.15, colors.primary)} !important;
      border-color: ${darken(0.15, colors.primary)} !important;
    }
  `,
  secondary: css`
    color: ${colors.white};
    background-color: ${colors.secondary};
    border: 1px solid ${colors.secondary};
    &:hover,
    &:focus {
      background-color: ${darken(0.09, colors.secondary)};
      border-color: ${darken(0.09, colors.secondary)};
    }
    &:active {
      background-color: ${darken(0.15, colors.secondary)} !important;
      border-color: ${darken(0.15, colors.secondary)} !important;
    }
  `,

  white: css`
    color: ${colors.primary};
    background-color: ${colors.white};
    border: 1px solid ${colors.white};
    border-radius: 30px;
    &:hover,
    &:focus {
      background-color: ${rgba(colors.white, 0.8)};
      border-color: ${rgba(colors.white, 0.8)};
    }
    &:active {
      background-color: ${rgba(colors.white, 0.7)};
      border-color: ${rgba(colors.white, 0.7)};
    }
  `,
}

const buttonSizes = {
  default: css`
    padding: 0 16px;
    height: 45px;
    font-size: 1rem;
  `,
  xs: css`
    padding: 0 12px;
    height: 32px;
    font-size: 0.9rem;
  `,
  sm: css`
    padding: 0 16px;
    height: 40px;
    font-size: 0.88rem;
  `,
  lg: css`
    padding: 0 30px;
    height: 50px;
    font-size: 1.02rem;
  `,
}

export const Button = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 2px;
  font-weight: 500;
  font-size: 1rem;
  transition: color ease-in-out 0.15s, border-color ease-in-out 0.15s,
    background-color ease-in-out 0.15s, transform ease-in-out 0.15s,
    opacity ease-in-out 0.15s;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    outline: none;
  }

  ${props => (props.theme ? buttonThemes[props.theme] : "")}
  ${props => (props.size ? buttonSizes[props.size] : "")}

  ${props =>
    props.long &&
    css`
      width: 100%;
      max-width: 240px;
    `}

    ${props =>
    props.full &&
    css`
      width: 100%;
      max-width: 100%;
    `}

    ${props =>
    props.disabled &&
    css`
      opacity: 0.65;
      pointer-events: none;
    `}

    ${props =>
    props.hasIcon &&
    css`
      .icon-before {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }

      .icon-after {
        width: 25px;
        height: 25px;
        margin-left: 8px;
      }
    `}

    ${props =>
    props.pressonclick &&
    css`
      &:active {
        transform: scale(0.98);
      }
    `}

    ${props =>
    props.alignLeft &&
    css`
      text-align: left;
      justify-content: flex-start;
    `}

    ${props =>
    props.isLink &&
    css`
      background-color: transparent;
      color: var(--color-grayscale6);
      border: none;
      padding: 1px 5px;
      height: auto;
      transition: opacity ease 0.2s;

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: var(--color-grayscale6);
        opacity: 0.8;
      }
    `}

    ${props =>
    props.isLoading &&
    css`
      &::after {
        content: "";
        z-index: 50;
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--color-white);
        border-top-color: transparent;
        animation: ${rotateAnim} 0.6s linear infinite;
      }
    `}
`

const rotateAnim = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

Button.propTypes = {
  theme: PropTypes.oneOf(Object.keys(buttonThemes)),
  size: PropTypes.oneOf(Object.keys(buttonSizes)),
  pressonclick: PropTypes.bool,
  isLink: PropTypes.bool,
  hasIcon: PropTypes.bool,
  alignLeft: PropTypes.bool,
  long: PropTypes.bool,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  theme: "default",
  size: "default",
  pressonclick: false,
  isLink: false,
  hasIcon: false,
  alignLeft: false,
  long: false,
  full: false,
  disabled: false,
}
